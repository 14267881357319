import { render, staticRenderFns } from "./clause_vac.vue?vue&type=template&id=724c3325&scoped=true"
import script from "./clause_vac.vue?vue&type=script&lang=js"
export * from "./clause_vac.vue?vue&type=script&lang=js"
import style0 from "./clause_vac.vue?vue&type=style&index=0&id=724c3325&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724c3325",
  null
  
)

export default component.exports