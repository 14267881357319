<template>
	<div>
		<van-nav-bar
			title="預約條款"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="clause">
			<div class="header_bg">
				<van-image class="clause_banner" :src="require('@/assets/images/vaccines/clause_banner.png')"></van-image>
				<van-image class="clause_title" :src="require('@/assets/images/vaccines/clause_text.png')"></van-image>
		
				<div class="header_tag">為自己,為家人,赴港接種復必泰二價疫苗</div>
			</div>
		
			<div class="clause_wrap">
				<div class="explain">根據香港政府對赴港自費接種復必泰二價mRNA疫苗的政策要求，接種醫院需要查看您的基礎免疫接種記錄（即接種2針及以上滅活疫苗），以確保符合接種程序。</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">復必泰二價mRNA疫苗</div> 
					</div>
					<div class="clause_item_text">
						復必泰二價mRNA疫苗是一款中德合作的新一代mRNA疫苗，由復星醫藥和德國BioNTech合作開發，在全球超60個國家和地區獲批，
					</div>
					<div class="fbt_cell">
						<div class="fbt_cell_lab"><span>全球供應超5.5億劑，</span>並已在我國港澳地區廣泛使用，<span>可全面覆蓋奧密克戎譜系變異毒株</span>（BA.4\XBB\CH.11等）。</div> 
						<van-image class="fbt_img" :src="require('@/assets/images/vaccines/fbt_img.png')"></van-image>
					</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">病毒仍在流行</div> 
					</div>
					<div class="clause_man">
						<van-image class="man_img" :src="require('@/assets/images/vaccines/man_img.png')"></van-image>
						<van-image class="clause_man_img1" :src="require('@/assets/images/vaccines/virus_img1.png')"></van-image>
						<van-image class="clause_man_img2" :src="require('@/assets/images/vaccines/virus_img2.png')"></van-image>
						<van-image class="clause_man_img3" :src="require('@/assets/images/vaccines/virus_img3.png')"></van-image>
						<van-image class="clause_man_img4" :src="require('@/assets/images/vaccines/virus_img4.png')"></van-image>
					</div>
					<div class="clause_virus">
						<div class="clause_item_cell">
							<div class="clause_item_cell_lab">毒株更多</div>
							<div class="clause_item_cell_val">病毒不斷進化，新的變異株XBB免疫逃逸能力更強，感染力更高</div>
						</div>
						<div class="clause_item_cell">
							<div class="clause_item_cell_lab">防控減弱</div>
							<div class="clause_item_cell_val">專家研判，國內第二輪疫情有可能在5-6月份出現</div>
						</div>
						<div class="clause_item_cell">
							<div class="clause_item_cell_lab">易感人群</div>
							<div class="clause_item_cell_val">商旅、中老年及免疫功能薄弱者需重點防護</div>
						</div>
					</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">官方接種預約平台</div> 
					</div>
					<div class="pallet_title">香港政府認可的疫苗接種機構、核酸檢測採樣中心。</div>
					<van-image :src="require('@/assets/images/vaccines/pallet_img.png')" class="pallet_img"></van-image>
					<div class="pallet_title2">香港體檢集團憑着逾15年的專業管理，致力提倡預防醫學的觀念，除了專業的體檢服務外，更是香港政府認可的新冠疫苗接種私營機構，及核酸檢驗採樣中心。新冠疫情期間為香港市民提供專業的疫苗及核酸服務</div>
					<div class="pallet_cell">
						<van-image :src="require('@/assets/images/vaccines/pallet_img1.png')" class="pallet_img_s"></van-image>
						<div class="pallet_cell_info">
							<div class="pallet_cell_title">全港超過11個接種點</div>
							<div class="pallet_cell_val">專業的場地讓您體驗舒心的輪候，接種，休息一站式服務</div>
						</div>
					</div>
					<div class="pallet_cell">
						<van-image :src="require('@/assets/images/vaccines/pallet_img2.png')" class="pallet_img_s"></van-image>
						<div class="pallet_cell_info">
							<div class="pallet_cell_title">专业标准化</div>
							<div class="pallet_cell_val">標準化培訓，資質齊全，正規醫護人員為您提供貼心的接種服務</div>
						</div>
					</div>
					<div class="pallet_cell">
						<van-image :src="require('@/assets/images/vaccines/pallet_img3.png')" class="pallet_img_s"></van-image>
						<div class="pallet_cell_info">
							<div class="pallet_cell_title">透明公開</div>
							<div class="pallet_cell_val">苗源可追溯，臨床報告可公開</div>
						</div>
					</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">預約須知</div> 
					</div>
					<div class="a_title">適合接種人群：</div>
					<div class="info_box">
						<div class="info_box_header">接種復必泰二價mRNA疫苗，<span>需同時滿足以下條件：</span></div>
						<div class="info_box_cell">年齡<span>12周歲</span>及以上</div>
						<div class="info_box_cell2"><span>完成接種</span>COVID-19疫苗基礎免疫</div>
						<div class="info_box_cell3">與前一針接種的新冠疫苗相隔至少<span>90</span>天(陽康後，最快28天可接種)</div>
					</div>
		
					<div class="a_title">不建議接種人群：</div>
					<div class="a_box">如果您對藥物的活性物質或任何其他成分有過敏反應，不建議接種。</div>
					<div class="a_title">警告和注意事項：</div>
					<div class="a_title_t">如果您有以下情況，請諮詢醫生:</div>
					<div class="b_box">以往在接種任何其他疫苗、復必泰或復必泰原始株/Omicron變異株 BA.4-5疫苗後，您曾出現過嚴重過敏反應或呼吸問題。</div>
					<div class="b_box">您對疫苗接種過程感到焦慮，或曾在任何針頭注射後暈倒。</div>
					<div class="b_box">您患有嚴重疾病或伴有高燒的感染。但是，如果您出現輕微發燒或上呼吸道感染（如感冒），您可以接種本疫苗。</div>
					<div class="b_box">您有出血問題，容易出現瘀青或正在使用預防血凝塊的藥物。</div>
					<div class="b_box">由於HIV感染等疾病或影響免疫系統的藥物（如皮質類固醇），您的免疫系統減弱。</div>
					<div class="a_title">接種後留觀：</div>
					<div class="a_title_t">建議接種後<span>留觀30分鐘</span>或遵循診所要求</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">服務內容</div> 
					</div>
					<div class="clause_serve"> 
						<van-image class="appoint_icon" :src="require('@/assets/images/vaccines/clause_appoint_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">疫苗預約服務</div>
							<div class="clause_serve_text">我們為您預約疫苗接種時間，發送接種指南及短訊通知<span>（含1針次預約）</span></div>
						</div>
					</div>
					<div class="clause_serve"> 
						<van-image class="appoint_icon" :src="require('@/assets/images/vaccines/clause_safe_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">香港政府認可的疫苗接種中心，安全、專業、可靠</div>
							<div class="clause_serve_text">我們為您預約香港體檢、香港體檢中心旗下分部、康健國際旗下診所等正規體檢機構、醫療中心，確保您的接種安全和體驗。</div>
						</div>
					</div>
					<div class="clause_serve"> 
						<van-image class="appoint_icon" :src="require('@/assets/images/vaccines/clause_about_iocn.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">接種相關服務</div>
							<div class="clause_serve_text">代收疫苗費用和接種費用服務</div>
						</div>
					</div>
					<div class="a_title_t">本套餐已包含疫苗費用/接種費用/代預約費</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">預約流程</div> 
					</div>
		
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_buy_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">1.購買服務</div>
							<div class="clause_serve_text">選擇疫苗代訂服務下單並支付</div>
						</div>
					</div>
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_ksyy_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">2.開始預約</div>
							<div class="clause_serve_text">進入訂單頁面，點擊「查看服務」-「去查看」</div>
						</div>
					</div>
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_txxx_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">3.填寫接種人信息</div>
							<div class="clause_serve_text">姓名、港澳通行證、健康狀況等</div>
						</div>
					</div>
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_xzjg_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">4.選擇時間和機構</div>
							<div class="clause_serve_text">根據個人需求挑選合適的日期、時段和機構</div>
						</div>
					</div>
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_tjyy_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">5.提交預約</div>
							<div class="clause_serve_text">提交預約成功後，您將收到短訊通知，您可以在成功提交預約後的三天內改期或取消。如果取消，您將需要承擔一定的費用</div>
						</div>
					</div>
					<div class="clause_lc"> 
						<van-image class="lc_icon" :src="require('@/assets/images/vaccines/clause_dyjz_icon.png')"></van-image>
						<div class="clause_serve_info">
							<div class="clause_serve_title">6.到院接種</div>
							<div class="clause_serve_text">攜帶預約證件、接種證明和確認短訊，準時前往機構接種</div>
						</div>
					</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">接種前準備</div> 
					</div>
					<div class="clause_list">
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/cluase_sfz_icon.png')"></van-image>
							</div>
							<div class="list_item_title">1.港澳通行證及<br>簽證</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_dx_icon.png')"></van-image>
							</div>
							<div class="list_item_title">2.預約成功<br>短訊</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_jl_icon.png')"></van-image>
							</div>
							<div class="list_item_title">3.疫苗接種記錄<br>（微信或支付寶）</div>
						</div>
					</div>
				</div>
				<div class="clause_item">
					<div class="clause_item_title">
						<van-image class="clause_item_title_img" :src="require('@/assets/images/vaccines/clause_title.png')"></van-image>
						<div class="clause_item_title_text">接種流程</div> 
					</div>
					<div class="clause_list">
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_xx_icon.png')"></van-image>
							</div>
							<div class="list_item_title">1.展示預約<br>成功短訊</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_sort_icon.png')"></van-image>
							</div>
							<div class="list_item_title">2.登記排號</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_mz_icon.png')"></van-image>
							</div>
							<div class="list_item_title">3.接種前面診</div>
						</div>
					</div>
					<div class="clause_list">
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_ty_icon.png')"></van-image>
							</div>
							<div class="list_item_title">4.簽署接種<br>知情同意書</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_jz_icon.png')"></van-image>
							</div>
							<div class="list_item_title">5.接種疫苗</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_lg_icon.png')"></van-image>
							</div>
							<div class="list_item_title">6.留觀<br>30分鐘</div>
						</div>
						<div class="list_item">
							<div class="list_item_icon">
								<van-image class="sfz_icon" :src="require('@/assets/images/vaccines/clause_zm_icon.png')"></van-image>
							</div>
							<div class="list_item_title">7.領取<br>接種證明</div>
						</div>
					</div>
				</div>
				<div class="ref_wrap">
					<div class="ref_title">退改規則</div>
					<div class="ref_box">
						<div class="a_title">關於改期或修改機構</div>
						<div class="ref_text">1.預約提交成功後，您可在<span>三天內</span>修改預約時間或機構。<br>
						2.預約提交成功後，您可在<span>三天內</span>取消約，如果取消預約，您將需要承擔一定費用。</div>
					</div>
				   
					
					<div class="ref_title2">關於爽約</div>
					<div class="ref_text2">預約成功後，除不可抗力因素外，客戶無法如期前往機構接種，視為放棄，該訂單不退款。</div>
					<div class="ref_title2">退款特別說明</div>
					<div class="">
						<div class="ref_text2">您購買後因個人原因導致的退款:</div>
						<div class="ref_cell">所有商品，若未預約或在三天內取消預約，可申請退款。</div>
						<div class="ref_cell2">所有商品，若已成功提交預約申請超過三天，均不支持退款。</div>
					</div>
				</div>
			</div>
			<div class="tips">以上條款，請您知悉，如您購買，即默認接受條款。後續事宜將嚴格按照以上條款進行，謝謝!</div>
			<div class="foot">
				<van-button class="btn" @click="handleSub">我知道了</van-button>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog, Field } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Field)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesLogin',
		data (){
			return {
				
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleSub() {
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.qjc-nav-bar {
		z-index: 999 !important;
	}
	.clause{
		width: 100%;
		min-height: 100vh;
		padding: 0.88rem 0;
		background-color: #fff;
		text-align: left;
	}
	.header_bg {
	    position: relative;
	    width: 100%;
	    height: 4.36rem;
	}
	.clause_banner {
	    position: absolute;
	    width: 100%;
	    height: 4.36rem;
	}
	.clause_title {
	    position: absolute;
	    width: 6.08rem;
	    height: 1.68rem;
	    top: 0.50rem;
	    left: 0.76rem;
	    z-index: 3;
	}
	
	.header_tag {
	    position: absolute;
	    /* width: 3.54rem; */
	    height: 0.52rem;
	    padding: 0 0.30rem;
	    background: linear-gradient(90deg, #FEAA37 0%, #FDCA3F 100%);
	    border-radius: 0.36rem 0 0.36rem 0;
	    z-index: 4;
	    bottom: 1.02rem;
	    left: 0.60rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFFFF;
	    letter-spacing: 2px;
	}
	
	.clause_wrap {
	    position: relative;
	    width: 100%;
	    padding: 0 0.36rem 0.36rem;
	    background-color: #4584E3;
	    z-index: 9;
	    margin-top: -0.38rem;
	}
	
	.explain {
	    width: 100%;
	    padding: 0.30rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #EF8D38;
	    line-height: 0.38rem;
	    background-color: #fff;
	    border: 0.08rem solid #A1CDFE;
	    border-radius: 0.16rem;
	}
	.clause_item {
	    position: relative;
	    width: 100%;
	    padding: 0.70rem 0.30rem 0.30rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.33rem;
	    background-color: #fff;
	    border: 0.08rem solid #A1CDFE;
	    border-radius: 0.16rem;
	    margin-top: 0.58rem;
	}
	.clause_item_title {
	    position: absolute;
	    top: -0.36rem;
	    left: 1.38rem;
	    width: 4.20rem;
	    height: 0.80rem;
	
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.clause_item_title_img {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 4.20rem;
	    height: 0.80rem;
	}
	.clause_item_title_text {
	    position: relative;
	    z-index: 9;
	    font-size: 0.36rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #FFFFFF;
	}
	
	.clause_item_text {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.38rem;
	}
	.clause_item_text span {
	    color: #EF8D38;
	}
	
	.clause_item_box {
	    width: 100%;
	    background-color: #E3EFFF;
	    border-radius: 0.16rem;
	}
	.box_header {
	    width: 100%;
	    height: 0.72rem;
	    display: flex;
	    align-items: center;
	    font-size: 0.28rem;
	    padding: 0 0.20rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #FFFFFF;
	    background-color: #4388FF;
	    border-radius: 0.16rem 0.16rem 0 0;
	}
	.box_info {
	    width: 100%;
	    padding: 0.32rem 0.20rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.38rem; 
	}
	.box_info span {
	    color: #EF8D38;
	}
	.box_info_top {
	    margin-top: 0.38rem;
	}
	
	.box_info_tip {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.30rem;
	    letter-spacing: 0.02rem;
	    margin-bottom: 0.12rem;
	}
	
	.info_table{
	    margin: 0.28rem 0 0.22rem;
	}
	
	.a_title {
	    position: relative;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Heavy, PingFang SC;
	    font-weight: 800;
	    color: #EF8D38;
	    line-height: 0.38rem;
	    margin-top: 0.32rem;
	}
	.a_title::before {
	    position: absolute;
	    bottom: 0.04rem;
	    left: 0;
	    content: '';
	    width: 2.16rem;
	    height: 0.12rem;
	    background: linear-gradient(90deg, rgba(239,141,56,0.26) 0%, rgba(255,255,255,0) 100%);
	}
	.a_box {
	    width: 100%;
	    padding: 0.20rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    letter-spacing: 0.01rem;
	    color: #32363E;
	    line-height: 0.38rem;
	    background-color: #E3EFFF;
	    border-radius: 0.16rem;
	    margin-top: 0.20rem;
	}
	.a_title_t {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.38rem;
	    margin-top: 0.16rem;
	}
	.b_box {
	    width: 100%;
	    padding: 0.20rem;
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    letter-spacing: 0.01rem;
	    color: #32363E;
	    line-height: 0.38rem;
	    background-color: #F1F6FE;
	    border-radius: 0.16rem;
	    margin-top: 0.20rem;
	}
	
	.a_title_t span{
	    color: #EF8D38;
	}
	
	.clause_serve {
	    display: flex;
	    width: 100%;
	    padding: 0.20rem;
	    background-color: #E3EFFF;
	    border-radius: 0.16rem;
	    margin-bottom: 0.16rem;
	}
	.appoint_icon {
	    width: 0.44rem;
	    height: 0.44rem;
	    margin-top: 0.10rem;
	}
	.clause_serve_info {
	    flex: 1;
	    margin-left: 0.28rem;
	}
	.clause_serve_title {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Heavy, PingFang SC;
	    font-weight: 800;
	    color: #EF8D38;
	    line-height: 0.38rem;
	}
	.clause_serve_text {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.28rem;
	    letter-spacing: 1px;
	    margin-top: 0.08rem;
	}
	.clause_serve_text span {
	    color: #EF8D38;
	}
	
	.clause_lc {
	    width: 100%;
	    display: flex;
	    margin-bottom: 0.36rem;
	}
	.clause_lc:nth-last-child(1) {
	    margin-bottom: 0;
	}
	.lc_icon {
	    width: 0.52rem;
	    height: 0.52rem;
	}
	
	
	.clause_list {
	    width: 100%;
	    display: flex;
	    justify-content: space-around;
	    margin-top: 0.16rem;
	}
	.list_item_icon {
	    text-align: center;
	    height: 0.84rem;
	    margin-bottom: 0.16rem;
	}
	.sfz_icon {
	    width: 0.84rem;
	    height: 0.84rem;
	}
	.list_item_title {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #4388FF;
	    line-height: 0.28rem;
	    text-align: center;
	}
	
	.b_box_title {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Heavy, PingFang SC;
	    font-weight: 800;
	    color: #EF8D38;
	    line-height: 0.28rem;
	    margin-bottom: 0.10rem;
	}
	
	.b_box_p {
	    font-size: 0.24rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.28rem;
	    letter-spacing: 0.02rem;
	    padding-left: 0.18rem;
	    position: relative;
	    margin-bottom: 0.12rem;
	}
	.b_box_p:nth-last-child(1) {
	    margin-bottom: 0;
	}
	.b_box_p::before {
	    position: absolute;
	    top: 0.08rem;
	    left: 0;
	    content: '';
	    width: 0.08rem;
	    height: 0.08rem;
	    border-radius: 50%;
	    background-color: #EF8D38;
	}
	
	.foot {
	    width: 100%;
	    margin-top: 0.80rem;
	    padding: 0 0.30rem;
	}
	.btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFCFC;
	    background-color: #6681FA;
	    border-radius: 0.16rem;
	}
	
	
	
	
	.info_table {
	    width: 100%;
	    border: 0.02rem solid #6883FB;
	    border-radius: 0.16rem;
	}
	.info_table_header {
	    width: 100%;
	    height: 1.04rem;
	    display: flex;
	    align-items: center;
	    background-color: #6883FB;
	    border-radius: 0.16rem 0.16rem 0 0;
	}
	.table_header_a {
	    width: 1.28rem;
	    height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFFFF;
	    border-right: 0.01rem solid #E5E8F5;
	}
	
	.table_header_b {
	    width: 2.38rem;
	    height: 100%;
	    padding-top: 0.18rem;
	    /* display: flex; */
	    /* align-items: center; */
	    /* justify-content: center; */
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFFFF;
	    border-right: 0.01rem solid #E5E8F5;
	    text-align: center;
	}
	.table_header_c {
	    flex: 1;
	    height: 100%;
	    padding-top: 0.18rem;
	    /* display: flex; */
	    /* align-items: center; */
	    /* justify-content: center; */
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #FFFFFF;
	    text-align: center;
	}
	
	.table_wrap {
	    width: 100%;
	    display: flex;
	    background-color: #fff;
	    border-radius: 0 0 0.16rem 0.16rem;
	}
	
	.table_wrap_left {
	    width: 3.82rem;
	    display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	}
	.table_wrap_a {
	    width: 1.28rem;
	    height: 1.56rem;
	    padding-left: 0.10rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 0.24rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    border-right: 0.01rem solid #E5E8F5;
	}
	
	.border-bottom {
	    border-bottom: 0.01rem solid #E5E8F5;
	}
	
	.table_wrap_b {
	    position: relative;
	    width: 2.38rem;
	    height: 1.56rem;
	    padding-top: 0.18rem;
	    /* display: flex;
	    align-items: center;
	    justify-content: center; */
	    font-size: 0.24rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    border-right: 0.01rem solid #E5E8F5;
	}
	.table_wrap_b view {
	    position: relative;
	    padding-left: 0.26rem;
	}
	.table_wrap_b view::before {
	    position: absolute;
	    top: 0.16rem;
	    left: 0.10rem;
	    content: '';
	    width: 0.08rem;
	    height: 0.08rem;
	    border-radius: 50%;
	    background-color: #EF8D38;
	}
	.table_wrap_c {
	    width: 2.00rem;
	    padding-top: 1.00rem;
	    font-size: 0.24rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    text-align: center;
	}
	.table_wrap_c span {
	    font-size: 0.20rem;
	}
	
	
	
	.info_box {
	    width: 100%;
	    padding: 0.20rem;
	    margin-top: 0.20rem;
	    background-color: #E3EFFF;
	}
	.info_box_header {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #000000;
	    line-height: 0.38rem;
	    letter-spacing: 0.02rem;
	}
	.info_box span {
	    color: #EF8D38;
	}
	
	.info_box_cell {
	    position: relative;
	    padding-left: 0.54rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #000000;
	    line-height: 0.38rem;
	    letter-spacing: 0.02rem;
	    margin-top: 0.24rem;
	}
	.info_box_cell::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    content: '1';
	    color: #fff;
	    text-align: center;
	    font-size: 0.28rem;
	    width: 0.32rem;
	    height: 0.32rem;
	    border-radius: 50%;
	    background-color: #4388FF;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.info_box_cell2 {
	    position: relative;
	    padding-left: 0.54rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #000000;
	    line-height: 0.38rem;
	    letter-spacing: 0.02rem;
	    margin-top: 0.24rem;
	}
	.info_box_cell2::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    content: '2';
	    color: #fff;
	    width: 0.32rem;
	    height: 0.32rem;
	    border-radius: 50%;
	    background-color: #4388FF;
	    text-align: center;
	    font-size: 0.28rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.info_box_cell3 {
	    position: relative;
	    padding-left: 0.54rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #000000;
	    line-height: 0.38rem;
	    letter-spacing: 0.02rem;
	    margin-top: 0.24rem;
	}
	.info_box_cell3::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    content: '3';
	    color: #fff;
	    width: 0.32rem;
	    height: 0.32rem;
	    border-radius: 50%;
	    background-color: #4388FF;
	    text-align: center;
	    font-size: 0.28rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.ref_wrap {
	    width: 100%;
	    margin-top: 1.04rem;
	}
	.ref_box {
	    width: 100%;
	    padding: 0.02rem 0.30rem 0.30rem;
	    margin-top: 0.32rem;
	    background-color: #FFFCE3;
	}
	
	.ref_title {
	    font-size: 0.36rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #FFFFFF;
	    line-height: 0.42rem;
	    text-align: center;
	}
	.ref_text {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.38rem;
	    margin-top: 0.20rem;
	}
	
	.ref_text span {
	    color: #EF8D38;
	}
	.ref_title2 {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #FFFFFF;
	    line-height: 0.38rem;
	    margin-top: 0.32rem;
	}
	.ref_text2 {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFFFF;
	    line-height: 0.38rem;
	    letter-spacing: 1px;
	    margin-top: 0.20rem;
	}
	.ref_cell {
	    position: relative;
	    padding-left: 0.54rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFFFF;
	    line-height: 0.33rem;
	    letter-spacing: 1px;
	    margin-top: 0.16rem;
	}
	
	.ref_cell::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    content: '1';
	    color: #fff;
	    width: 0.32rem;
	    height: 0.32rem;
	    border-radius: 50%;
	    border: 0.01rem solid #fff;
	    text-align: center;
	    font-size: 0.28rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.ref_cell2 {
	    position: relative;
	    padding-left: 0.54rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFFFF;
	    line-height: 0.33rem;
	    letter-spacing: 1px;
	    margin-top: 0.16rem;
	}
	
	.ref_cell2::before {
	    position: absolute;
	    top: 0;
	    left: 0;
	    content: '2';
	    color: #fff;
	    width: 0.32rem;
	    height: 0.32rem;
	    border-radius: 50%;
	    border: 0.01rem solid #fff;
	    text-align: center;
	    font-size: 0.28rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	
	.tips {
	    width: 100%;
	    padding: 0.22rem 0.30rem;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #4584E3;
	    line-height: 0.38rem;
	    background-color: #EBF2FE;
	}
	
	.pallet_title {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #32363E;
	    line-height: 0.38rem;
	    letter-spacing: 1px;
	}
	.pallet_img {
	    width: 100%;
	    height: 3.56rem;
	    margin-top: 0.22rem;
	}
	.pallet_title2 {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.38rem;
	    letter-spacing: 1px;
	    margin-top: 0.24rem;
	}
	.pallet_cell {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    margin-top: 0.32rem;
	}
	
	.pallet_img_s {
	    width: 2.72rem;
	    height: 1.96rem;
	    margin-right: 0.26rem;
	}
	.pallet_cell_info {
	    flex: 1;
	}
	.pallet_cell_title {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Semibold, PingFang SC;
	    font-weight: 600;
	    color: #EF8D38;
	    line-height: 0.38rem;
	    letter-spacing: 1px;
	}
	.pallet_cell_val {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.38rem;
	    letter-spacing: 1px;
	    margin-top: 0.16rem;
	}
	.clause_man {
	    position: relative;
	    width: 100%;
	    height: 4.50rem;
	}
	.man_img {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 4.50rem;
	    z-index: 2;
	}
	
	.clause_virus {
	    position: relative;
	    width: 100%;
	    padding: 0 0.20rem;
	    background-color: #E3EFFF;
	    border-radius: 0.16rem;
	    margin-top: -0.50rem;
	    z-index: 9;
	}
	
	.clause_item_cell:nth-last-child(1) {
	    border-bottom: none;
	}
	
	.clause_item_cell {
	    display: flex;
	    align-items: center;
	    width: 100%;
	    padding: 0.32rem 0;
	    border-bottom: 0.01rem dashed #FFFFFF;
	}
	.clause_item_cell_lab {
	    font-size: 0.28rem;
	    font-family: PingFang SC-Heavy, PingFang SC;
	    font-weight: 800;
	    color: #EF8D38;
	    line-height: 0.38rem;
	    margin-right: 0.44rem;
	}
	.clause_item_cell_val {
	    flex: 1;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Heavy, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.38rem;
	}
	
	.clause_man_img1 {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 2.30rem;
	    height: 1.10rem;
	    z-index: 3;
	}
	.clause_man_img2 {
	    position: absolute;
	    bottom: 1.20rem;
	    left: 0;
	    width: 2.38rem;
	    height: 0.70rem;
	    z-index: 3;
	}
	.clause_man_img3 {
	    position: absolute;
	    top: 0.20rem;
	    right: 0;
	    width: 2.36rem;
	    height: 0.70rem;
	    z-index: 3;
	}
	.clause_man_img4 {
	    position: absolute;
	    bottom: 1.20rem;
	    right: 0;
	    width: 2.02rem;
	    height: 1.10rem;
	    z-index: 3;
	}
	.fbt_cell {
	    display: flex;
	    align-items: center;
	    width: 100%;
	}
	
	.fbt_cell_lab {
	    flex: 1;
	    font-size: 0.28rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #32363E;
	    line-height: 0.38rem;
	}
	.fbt_cell_lab span {
	    color: #EF8D38;
	}
	
	.fbt_img {
	    width: 2.04rem;
	    height: 1.54rem;
	}
</style>
